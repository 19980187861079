import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutDivider, mobileAbout, mobileAboutDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            style="display: block"
            src={mobileAboutDivider}
            w="100%"
            alt="Sahali Tongdak Korean Chicken Restaurant About"
          />
          <CFImage
            mt="15px"
            src={mobileAbout}
            w="100%"
            alt="Sahali Tongdak Korean Chicken Restaurant About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView>
          <CFImage
            src={aboutDivider}
            w="100%"
            alt="Sahali Tongdak Korean Chicken Restaurant About"
          />
          <CFImage
            ph="30px"
            pv="70px"
            src={about}
            w="100%"
            alt="Sahali Tongdak Korean Chicken Restaurant About"
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
